.letter-wrapper {
    font-family: 'Brush Script MT', cursive;
    display: inline-block;
    width: 30%;
    padding: 5vw;
    background-color: #E0C9A6;
    margin: 5vw;
}

.home-header {
    text-align: left;
    height: 20vw;
    background-color: #E83F6F;
}

.app-title {
    font-family: 'Brush Script MT', cursive;
    display: inline-block;
    padding: 15px;
    font-size: 10vw;
    color: white;
}

.unlock-button-wrapper {
    display: inline-block;
    float: right;
    padding: 10px;
    color: white;
    background-color: #ff6b6b;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 1px 1px #80808075;
}

.letter {
    position: absolute;
    font-family: 'Brush Script MT', cursive;
    padding: 5vw;
    background-color: #E0C9A6;
    margin: 5vw;
    outline: none;
    width: 80%;
    text-align: left;
}

.unlock-letter-wrapper {
    position: absolute;
    padding: 5vw;
    background-color: #E0C9A6;
    margin: 5vw;
    outline: none;
    width: 80%;
    text-align: left;
}

.unlock-letter-input {
    display: block;
    margin: 2em auto;
    border: none;
    padding: 0;
    width: 7.5ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/7ch 2px no-repeat;
    font: 5ch droid sans mono, consolas, monospace;
    letter-spacing: 0.5ch;
}

.unlock-letter-input:focus {
    outline: none;
    color: white;
}

.unlock-letter-modal-title {
    font-family: 'Brush Script MT', cursive;
    color: white;
    font-size: 10vw;
}

.letter-code-error-message-wrapper {
    background-color: #ff9191;
    border-color: red;
    color: white;
    border-radius: 10px;
    padding: 5px;
    font-size: 5.5vw;
}

.letter-letter-date {
    padding-bottom: 5vw;
}