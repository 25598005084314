.App {
  text-align: center;
}

body {
  background-color: #EB5781;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.password-box-wrapper {
  display: inline-block;
  background-color: #E83F6F;
  border-radius: 40px;
  margin-top: 40%;
  padding: 10px;
}

.password-box-image-wrapper {
  /* height: 50px;
  width: auto; */
}

.password-box-title-wrapper {
  color: white;
  font-size: 10vw;
  padding: 0px;
  font-family: 'Brush Script MT', cursive;
}

.password-input-wrapper {

}

.password-input {
  border-radius: 45px;
  height: 30px;
  padding: 10px;
  font-size: 20px;
  margin: 10px;
  border: none;
  box-shadow: 2px 3px #00000069;
  font-family: 'Brush Script MT', cursive;
  outline: none;
}

.send-button {
  border-radius: 45px;
  height: 30px;
  padding: 10px;
  font-size: 20px;
  margin: 10px;
  border: none;
  box-shadow: 2px 3px #00000069;
  font-family: 'Brush Script MT', cursive;
}

.lexie-logo {
  border-radius: 30vw;
  height: 40vw;
}

.password-error-message-wrapper {
  background-color: #ff9191;
  border-color: red;
  color: white;
  border-radius: 10px;
  padding: 5px;
  font-size: 2.5vw;
}